<template>
  <div>
    <div>
      <div class="content-header container-fluid bg-white">
        <div class="row">
          <div class="col-sm-4">
            <h4><i class="ti-direction-alt"></i> 工作流转 | 所有记录</h4>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="row">
          <div class="col-md-12">
            <!-- <form id="fm_export" method="post" action="/wfl/act/export" target="_blank" style="display: inline-block;">
              <input type="hidden" name="KEY" v-model="grid.sea.KEY" />
              <input type="hidden" name="CONF_SN" v-model="grid.sea.CONF_SN" />
              <input type="hidden" name="DTS" v-model="grid.sea.DTS" />
              <input type="hidden" name="DTE" v-model="grid.sea.DTE" /> -->
            <button type="submit" class="el-button el-button--success" @click="exportExcel">
              <span>导出Excel</span>
            </button>
            <!-- </form> -->
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="w-block" style="padding: 2px;">
              <el-select v-model="grid.sea.CONF_SN" @change="filter" clearable placeholder="请选择分类">
                <el-option :label="it.NAME" :value="it.SN" v-for="it in ls_conf" :key="it.ID" />
              </el-select>
              <el-date-picker v-model="grid.date_range" @change="filter" type="daterange" value-format="yyyy-MM-dd"
                range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" style="margin-left: 5px" />
              <el-input v-model="grid.sea.KEY" placeholder="请输入关键字" style="width: 300px;margin-left: 5px">
                <el-button slot="append" icon="el-icon-search" @click="filter"></el-button>
              </el-input>
            </div>
            <wgrid :data="grid.ls" v-loading="grid.loading" :total="grid.total" :page-index="grid.sea.PAGE_INDEX + 1"
              @page-changed="loadPage">
              <el-table-column prop="CONF_NAME" label="分类" width="100" align="center" />
              <el-table-column label="内容描述" width="300">
                <template slot-scope="scope">
                  <span>{{ scope.row.TITLE }}</span>
                  <span> {{ scope.row.DESCRIPTION }}</span>
                  <p v-if="scope.row.STEP == 91" style="color: red">理由：{{ scope.row.REFUSE_DESC }}</p>
                </template>
              </el-table-column>
              <el-table-column prop="STEA_NAME" label="提交人" width="100" align="center" />
              <el-table-column prop="STEP_NAME" label="步骤" width="80" align="center" />
              <el-table-column label="提交日期" width="100">
                <template slot-scope="scope" align="center">{{ scope.row.CREATED_DT | datetime('YYYY-MM-DD') }}</template>
              </el-table-column>
              <el-table-column prop="LAST_PRO" label="处理人" width="180" />
              <el-table-column label="操作" width="140">
                <template slot-scope="scope">
                  <el-button type="text" icon="el-icon-edit" @click="edit(scope.row)">详情</el-button>
                </template>
              </el-table-column>
              <el-table-column />
            </wgrid>
          </div>
        </div>
      </div>
    </div>
    <div>
      <Detail ref="detail" />
    </div>
  </div>
</template>
<style></style>
<script>
import Wgrid from "@/components/wgrid";
import Detail from "./detail";
import jsFileDownload from 'js-file-download'
export default {
  name: "index",
  components: { Wgrid, Detail },
  data() {
    return {
      grid: {
        sea: {
          PAGE_SIZE: 12,
          PAGE_INDEX: 0,
          FOR: "ALL"
        },
        ls: [],
        total: 0,
        loading: false
      },
      ls_conf: []
    }
  },
  created() {
    this.getConf();
    this.getList();
  },
  methods: {
    getConf() {
      let self = this;
      this.whale.remote.getCollection({
        url: "/api/School/WFL/WConfigApi/GetList",
        completed(its) {
          self.ls_conf = its
        }
      })
    },
    filter() {
      if (this.grid.date_range != null && this.grid.date_range.length > 1) {
        this.grid.sea.DTS = this.grid.date_range[0];
        this.grid.sea.DTE = this.grid.date_range[1];
      }

      this.grid.sea.PAGE_INDEX = 0;
      this.grid.ls = [];
      this.getList();
    },
    loadPage(pi) {
      this.grid.sea.PAGE_INDEX = pi - 1;
      this.getList();
    },
    getList() {
      let self = this;
      this.grid.loading = true;
      this.whale.remote.getCollection({
        url: "/api/School/WFL/Act/GetList",
        data: this.grid.sea,
        completed: function (its, n) {
          self.grid.ls = its;
          self.grid.total = n;
          self.grid.loading = false;
        }
      })
    },
    edit(m) {
      this.$refs.detail.init(m);
    },
    exportExcel() {
      let self = this;
      this.grid.loading = true;
      const formData = new FormData()
      formData.append('KEY', this.grid.sea.KEY || '')
      formData.append('CONF_SN', this.grid.sea.CONF_SN || '')
      formData.append('DTS', this.grid.sea.DTS || '')
      formData.append('DTE', this.grid.sea.DTE || '')
      this.whale.remote.getExport({
        url: "/api/School/WFL/Act/Export",
        data: formData,
        completed: function (its) {
          // console.log('exls', its.Data)
          // self.whale.downLoad(its.Data)
          jsFileDownload(its,'导出工作流转所有记录.xlsx')
          self.grid.loading = false;
        }
      })
    }
  }
}
</script>

